import { Box } from "@mui/material"
import React from "react"
import { MainLayout } from "../common/MainLayout"

export type BuyETHProps = {}

export const BuyETH: React.FunctionComponent<BuyETHProps> = () => {
  return (
    <MainLayout>
      <div className="c-feature">
        <div className="c-feature__ttl">
          <h3 className="c-feature__ttl--01">ETHの買い方</h3>
          <p className="c-feature__ttl--02">
            （※ETHは国内取引所などで円で購入することが可能です。）
          </p>
        </div>
      </div>
      <div className="c-main">
        <div className="c-container mx-auto">
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            取引所の口座開設
          </Box>
          <div className="c-content">
            <p className="c-content__txt">
              ETHは国内取引所などで円で購入することが可能です。
              <br />
              取引所の口座を開設し、取引所でイーサを購入できたら、下記の手順でMetaMaskのアドレスを確認し、取引所からMetaMaskに送金することが可能です。
              <br />
            </p>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            MetaMaskのアドレスの確認
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                Ethereumメインネットにつながっていることを確認します。
              </Box>
              <div className="c-block__img">
                <img src="/images/buy_eth.png" alt="" />
              </div>
            </div>
            <Box
              className="c-block__frame"
              sx={{ borderColor: "primary.main" }}
            >
              <p className="c-block__frame--03">
                Ethereumメインネットにつながっていることを確認します。
                <br />
                メニューをクリックし、アカウントの詳細をクリックします。
                <br />
                MetaMaskのアドレスが表示されます。
              </p>
            </Box>
          </div>
          <Box
            className="c-ttl"
            sx={{
              backgroundColor: "primary.light",
              borderColor: "primary.main",
            }}
            component="h5"
          >
            MetaMaskへのETH送金
          </Box>
          <div className="c-content">
            <div className="c-block">
              <Box
                className="c-subTtl"
                sx={{
                  borderColor: "primary.main",
                }}
                component="h6"
              >
                取引所からの出金アドレスとしてMetaMaskのアドレスを指定して出金します。
              </Box>
              <br />
              <div className="c-content__txt">
                取引所から出金され、MetaMaskに入金確認されるまで時間がかかります。
                <br />
                早くても５分～10分程度ですが、取引所によっては最大で１～2日かかる場合もあるため入出金までお待ちください。
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
